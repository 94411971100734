import axios from "axios";

const host = process.env.REACT_APP_BACKEND_URL;
const adminId = process.env.REACT_APP_ADMIN_ID;
const apiKey = process.env.REACT_APP_MAP_API_KEY;

export const getSocketUrl = async () => {
  try {
    const { data } = await axios.get(`${host}/getSocketURL `);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getSpread = async () => {
  try {
    const { data } = await axios.get(`${host}/getSpread/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getRatio = async () => {
  try {
    const data = await axios.get(`${host}/getGoldRatioByScrap`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getAllImages = async () => {
  try {
    const data = await axios.get(`${host}/getAllImages/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const allNewses = async () => {
  try {
    const { data } = await axios.get(`${host}/allNewses/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const allCommodities = async () => {
  try {
    const { data } = await axios.get(`${host}/allCommodities/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const tvScreenLogout = async (value) => {
  try {
    const data = await axios.post(
      `${host}/tvScreenLogout`,
      JSON.stringify(value),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getConversionValue = async (base) => {
  try {
    const { data } = await axios.get(`${host}/getConversionValue/${base}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findLoaction = async (lat, lng) => {
  try {
    const { data } = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
export const findUserIp = async (lat, lng) => {
  try {
    const { data } = await axios.get(`https://api.ipify.org/?format=json`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const findBanners = async () => {
  try {
    const { data } = await axios.get(`${host}/getAllImages/${adminId}`);
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};

export const getLiveValueTypeForDisplay = async () => {
  try {
    const { data } = await axios.get(
      `${host}/getLiveValueTypeForDisplay/${adminId}`
    );
    return data;
  } catch (error) {
    return error.response.data.message;
  }
};
