import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "./page/Home";
import { useMediaQuery } from "@mui/material";
import MobileErrorPage from "./page/MobileErrorPage";

function App() {
  const isTvScreen = useMediaQuery("(min-width: 1000px)");
  return (
    <div>
      <ToastContainer />
      {isTvScreen ? (
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      ) : (
        <MobileErrorPage />
      )}
    </div>
  );
}

export default App;
