import firebase from "firebase";
const firebaseConfig = {
  apiKey: "AIzaSyAJ16gWpMChdxFgbyQB5IsR8tf7vAz71gw",
  authDomain: "tv-login-442fd.firebaseapp.com",
  projectId: "tv-login-442fd",
  storageBucket: "tv-login-442fd.appspot.com",
  messagingSenderId: "297928733541",
  appId: "1:297928733541:web:77bb1d7958d1ea6abe278c",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

export const firebaseAuth = firebaseApp.auth();
